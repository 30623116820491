import { Nullable } from './Nullable'
import { Practitioner } from './Practitioner'

export enum AppointmentType {
  Upcoming = 'UPCOMING',
  Canceled = 'CANCELED',
  Past = 'PAST',
}

export enum CancellationType {
  NO_SHOW = 'NO_SHOW',
  CANCELED = 'CANCELED',
  CANCELED_ON_TIME = 'CANCELED_ON_TIME',
  CANCELED_SHORT_TERM = 'CANCELED_SHORT_TERM',
  CANCELED_MOVED = 'CANCELED_MOVED',
  CANCELED_BY_PRACTICE = 'CANCELED_BY_PRACTICE',
  OTHER = 'OTHER',
}

export type Appointment = {
  canceled: Nullable<CancellationType>
  createdAt: string
  deleted: false
  duration: number
  modified: Nullable<string>
  patientReferenceId: string
  practitioner: Practitioner
  referenceId: string
  start: string
  title: string
}
