import { useQuery } from 'react-query'

import { AppointmentType } from 'src/@types/Appointment'

import { api } from '../../helpers'

const useVisitation = ({ appointmentReferenceId, type }: { appointmentReferenceId: string; type: AppointmentType }) =>
  useQuery(
    ['medical_records_patient_history_entry', appointmentReferenceId],
    () => api.medicalRecords.getVisitation(appointmentReferenceId),
    {
      enabled: Boolean(appointmentReferenceId) && type === AppointmentType.Past,
    }
  )

export default useVisitation
