import { TFunction } from 'next-i18next'

import { Gender } from 'src/@types/Person'
import { PractitionerRole, Practitioner } from 'src/@types/Practitioner'

const ALLOWED_ROLES = [PractitionerRole.DENTIST, PractitionerRole.HYGIENIST]

const getPractitionerRoles = (practitioner: Practitioner, t: TFunction) => {
  const practitionerPositions = practitioner?.positions ?? []
  const gender = practitioner?.gender === Gender.Female ? 'mrs' : 'mr'

  return practitionerPositions
    .filter((position) => ALLOWED_ROLES.includes(position.role))
    .map((position) => t(`common.doctor_speciality.${gender}.${position.role.toLowerCase()}`))
    .join(', ')
}

export default getPractitionerRoles
