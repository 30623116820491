import React, { ReactNode } from 'react'

import { Stack } from '@mui/material'

import { DataTestIds } from 'src/config/dataTestIds'

import * as S from './PractitionerDetails.styles'

export type PractitionerDetailsProps = {
  size?: 'large' | 'medium' | 'small'
  title?: ReactNode
  subTitle?: string
  description?: ReactNode
  avatar?: ReactNode
  rightCorner?: ReactNode
  footer?: ReactNode
}

const PractitionerDetails = ({
  size = 'medium',
  title,
  subTitle,
  description,
  avatar,
  rightCorner,
  footer,
  ...other
}: PractitionerDetailsProps) => (
  <Stack {...other}>
    <S.Header>
      {avatar && <S.Avatar>{avatar}</S.Avatar>}
      <S.HeaderContent>
        {title && (
          <S.Title data-testid={DataTestIds.PractitionerCardTitle} size={size}>
            {title}
            {rightCorner && <S.RightCorner>{rightCorner}</S.RightCorner>}
          </S.Title>
        )}
        {subTitle && (
          <S.SubTitle data-testid={DataTestIds.PractitionerCardSubTitle} size={size}>
            {subTitle}
          </S.SubTitle>
        )}
        {description && (
          <S.Description size={size} data-testid={DataTestIds.PractitionerCardDescription}>
            {description}
          </S.Description>
        )}
      </S.HeaderContent>
    </S.Header>

    {footer && <S.Footer>{footer}</S.Footer>}
  </Stack>
)

export default PractitionerDetails
