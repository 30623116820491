import React, { ReactNode } from 'react'

import {
  SwipeableDrawerProps,
  SwipeableDrawer as BaseSwipeableDrawer,
} from '@dentalux/ui-library-core/cjs/components/swipeable-drawer'

import { Box } from '@mui/material'

export interface DrawerProps extends Omit<SwipeableDrawerProps, 'open' | 'onOpen' | 'onClose'> {
  open: boolean
  children: ReactNode
  onClose?: () => void
  closeDrawer: () => void
  transitionDuration?: { enter: number; exit: number }
}

export const SwipeableDrawer = ({
  children,
  onClose,
  action,
  closeDrawer,
  transitionDuration = { enter: 900, exit: 600 },
  ...rest
}: DrawerProps) => (
  <BaseSwipeableDrawer
    onClose={() => {
      onClose && setTimeout(onClose, transitionDuration.exit)
      closeDrawer()
    }}
    onOpen={null}
    transitionDuration={transitionDuration}
    action={action ? <Box textAlign="center">{action}</Box> : null}
    {...rest}
  >
    {children}
  </BaseSwipeableDrawer>
)
