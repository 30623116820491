import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[800],
}))

export const Record = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),

  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(1),
  },
}))

const Span = styled('span')(({ theme }) => ({
  display: 'inline',

  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))

export const Label = styled(Span)(({ theme }) => ({
  ...theme.typography['Lato Emphasized 1'],
  paddingRight: theme.spacing(0.5),

  [theme.breakpoints.up('md')]: {
    paddingRight: theme.spacing(0),
  },
}))

export const Value = styled(Span)(({ theme }) => ({
  ...theme.typography['Lato Body 1'],
}))
