import { Box, Divider as BaseDivider, svgIconClasses, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import BaseSectionItem from '../SectionItem'

export const Root = styled(Box)<{ padded?: boolean }>(({ theme, padded = false }) => ({
  width: '100%',
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.grey[0],
  padding: 0,
  marginBottom: 0,
  boxShadow: 'unset',

  [theme.breakpoints.up(padded ? 'xs' : 'md')]: {
    padding: theme.spacing(3),
    boxShadow: theme.shadows[3],
    marginBottom: theme.spacing(2),
  },
}))

export const DateTime = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  [`.${svgIconClasses.root}`]: {
    color: theme.palette.grey[800],
    margin: theme.spacing(0, 1, 0, 2),

    [theme.breakpoints.up('md')]: {
      '&:first-of-type': {
        marginLeft: 0,
      },
    },
  },
}))

export const SectionItem = styled(BaseSectionItem)({})

export const Divider = styled(BaseDivider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}))

export const Text = styled(Typography)({})

Text.defaultProps = {
  variant: 'Lato Emphasized 1',
}
