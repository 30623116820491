import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import { SectionItemProps } from './SectionItem'

const shouldForwardProp = (prop) => prop !== 'orientation'

export const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.grey[800],

  gap: theme.spacing(2),
}))

export const Icon = styled(Box)({
  display: 'flex',
  flexGrow: 0,
})

export const Label = styled(Box, {
  shouldForwardProp,
})<{ orientation: SectionItemProps['orientation'] }>(({ theme, orientation }) => ({
  flex: orientation === 'horizontal' ? `0 0 ${theme.spacing(22)}` : 'unset',
  ...theme.typography[orientation === 'horizontal' ? 'Lato Body 1' : 'Lato Caption'],
  width: '100%',

  [theme.breakpoints.up('lg')]: {
    flex: orientation === 'horizontal' ? `0 0 ${theme.spacing(29)}` : 'unset',
  },
}))

export const Value = styled(Box)(({ theme }) => ({
  ...theme.typography['Lato Emphasized 1'],
  flexGrow: 1,
  wordBreak: 'break-word',
}))
