import React from 'react'

import { useTranslation } from 'next-i18next'

import { Link } from '@mui/material'

import { parseISO } from 'date-fns'
import { AppointmentType } from 'src/@types/Appointment'
import { Clinic } from 'src/@types/Clinic'
import { Nullable } from 'src/@types/Nullable'
import { Practitioner } from 'src/@types/Practitioner'
import { DataTestIds } from 'src/config/dataTestIds'
import { getClinicLocation, getClinicLocationUrl } from 'src/helpers/clinic'
import { formatMedium, formatTime } from 'src/helpers/date'
import useVisitation from 'src/hooks/api/useVisitation'
import useDateFnsConfig from 'src/hooks/useDateFnsConfig'
import useIsDesktop from 'src/hooks/useIsDesktop'

import * as S from './AppointmentView.styles'
import DesktopView from './components/DesktopView'
import MobileView from './components/MobileView'

export type AppointmentViewProps = {
  type: Nullable<AppointmentType>
  padded?: boolean
  treatmentTitle?: string
  appointmentReferenceId?: string | undefined
  practitioner?: Practitioner
  clinic: Partial<Clinic>
  dateOfVisit: string
}

const AppointmentView = ({
  type,
  appointmentReferenceId,
  dateOfVisit,
  padded,
  clinic,
  ...rest
}: AppointmentViewProps) => {
  const isDesktop = useIsDesktop()
  const { t } = useTranslation()
  const { data: visitation } = useVisitation({ appointmentReferenceId, type })

  const locale = useDateFnsConfig()

  const date = formatMedium(new Date(dateOfVisit), locale)
  const time = type === AppointmentType.Upcoming ? formatTime(parseISO(dateOfVisit), locale) : null

  const hasClinicLocation = clinic?.lat && clinic?.long

  const treatments = visitation?.dentalTreatments || []

  const View = isDesktop ? DesktopView : MobileView

  return (
    <S.Root padded={padded} data-testid={DataTestIds.AppointmentDetailedItem}>
      <View
        date={date}
        time={time}
        clinic={clinic}
        treatments={treatments}
        LocationLink={
          hasClinicLocation ? (
            <Link href={getClinicLocationUrl(clinic)} target="_blank">
              {getClinicLocation(clinic)}
            </Link>
          ) : null
        }
        labels={{
          treatment: t('b2c.appointments.treatment'),
          date: t('b2c.appointments.appointmentSchedule'),
          viewProfile: t('b2c.appointments.viewProfile'),
          callClinic: t('b2c.appointments.callTheClinic'),
          clinic: t('b2c.appointments.clinic'),
          details: t('b2c.appointments.details'),
          findUs: t('b2c.appointments.findUs'),
        }}
        {...rest}
      />
    </S.Root>
  )
}

export default AppointmentView
