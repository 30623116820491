import React from 'react'

import { useTranslation } from 'next-i18next'

import { DentalTreatment as DentalTreatmentsType } from 'src/@types/DentalTreatment'

import { getTreatedTeeth } from './DentalTreatments.helpers'
import * as S from './DentalTreatments.styles'

type DentalTreatmentProps = {
  data: DentalTreatmentsType[]
}

const DentalTreatments = ({ data = [] }: DentalTreatmentProps) => {
  const { t } = useTranslation()

  return (
    <S.Root>
      {data.map((dentalTreatment) => {
        const { permanent, primary } = getTreatedTeeth(dentalTreatment.toothPositions)

        const permanentTeethTitle = permanent.length > 0 ? `${t('common.tooth')} ${permanent.join()}` : ''
        const primaryTeethTitle = primary.length > 0 ? `${t('common.baby_tooth')} ${primary.join()}` : ''

        const title =
          permanentTeethTitle && primaryTeethTitle
            ? `${permanentTeethTitle}; ${primaryTeethTitle}`
            : permanentTeethTitle || primaryTeethTitle || null

        return (
          <S.Record key={dentalTreatment.referenceId}>
            {title && <S.Label>{title}</S.Label>}

            <S.Value>
              {dentalTreatment.contentfulKeys.map((key) => t(`b2c.dentaltreatments.${key}`)).join('; ')}
            </S.Value>
          </S.Record>
        )
      })}
    </S.Root>
  )
}

export default DentalTreatments
