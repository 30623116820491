import { QueryHookOptions, useQuery } from '@apollo/client'
import { Nullable } from 'src/@types/Nullable'
import { PersonClinic } from 'src/@types/PersonClinic'
import { usePersonId } from 'src/components/providers/PersonIdProvider'

import { CLINICS_QUERY } from '../queries/clinics'
import { ClinicsVariables } from '../types/ClinicsVariables'
import { PageInfo } from '../types/PageInfo'

type ClinicsResponse = { clinics: Nullable<{ pageInfo: PageInfo; data: PersonClinic[] }> }

const useClinics = (options: QueryHookOptions<ClinicsResponse, ClinicsVariables>) => {
  const personReferenceId = usePersonId()

  const { data, ...rest } = useQuery<ClinicsResponse>(CLINICS_QUERY, {
    ...options,
    variables: { personReferenceId, ...options.variables },
    skip: options.skip || !personReferenceId,
  })

  return { data: data?.clinics ?? undefined, ...rest }
}

export default useClinics
