import { TreatedTooth } from 'src/@types/DentalTreatment'

export const getTreatedTeeth = (toothPositions: TreatedTooth[]): { permanent: string[]; primary: string[] } => {
  return toothPositions.reduce(
    (acc, position) => {
      const toothPosition = position.toothPosition.replace(/[^0-9]/g, '')

      if (position.isPrimary) return { ...acc, primary: [toothPosition, ...acc.primary] }

      return { ...acc, permanent: [toothPosition, ...acc.permanent] }
    },
    { permanent: [], primary: [] }
  )
}
