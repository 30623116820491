import { useRouter } from 'next/router'

import { setBookingIds, setDefaultBookingContext, useBookingsDispatch } from 'src/components/providers/BookingsProvider'
import { usePersonId } from 'src/components/providers/PersonIdProvider'
import useClinics from 'src/graphql/hooks/useClinics'

export const useStartBook = () => {
  const router = useRouter()
  const personReferenceId = usePersonId()

  const dispatch = useBookingsDispatch()

  const { data: clinics, loading: clinicsLoading } = useClinics({
    skip: !personReferenceId,
  })

  const lastVisitedActiveClinic = [...(clinics?.data || [])]
    .sort((a, b) => (new Date(a.lastVisitedDateTime) > new Date(b.lastVisitedDateTime) ? -1 : 1))
    .find((c) => c.isClinicActive)

  return clinicsLoading
    ? undefined
    : () => {
        dispatch(setDefaultBookingContext())

        if (lastVisitedActiveClinic) {
          dispatch(
            setBookingIds({
              clinicReferenceId: lastVisitedActiveClinic.clinic.referenceId,
              appointmentBundleReferenceId: null,
            })
          )

          return router.push('/appointments/treatment')
        }

        return router.push('/appointments/clinic')
      }
}
