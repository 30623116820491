import React, { SyntheticEvent, ReactNode, CSSProperties } from 'react'

import { useTranslation } from 'next-i18next'

import { useStartBook } from 'src/hooks/useStartBook'

import { EmptyStateButton } from '../EmptyStateButton/EmptyStateButton'

export const Book = ({
  onClick,
  label,
  className,
  disabled,
  style,
  dataTestId,
}: {
  label?: ReactNode | string
  onClick?: (e: SyntheticEvent) => void
  className?: string
  disabled?: boolean
  style?: CSSProperties
  dataTestId?: string
}) => {
  const { t } = useTranslation()

  const startBook = useStartBook()

  if (!startBook) return null

  return (
    <EmptyStateButton
      data-testid={dataTestId}
      style={style}
      className={className}
      onClick={onClick || startBook}
      disabled={disabled}
    >
      {label || t('b2c.buttons.bookNewAppointment')}
    </EmptyStateButton>
  )
}
