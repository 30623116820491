import { Typography, Stack, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Header = styled('div')(() => ({
  display: 'flex',
  width: '100%',
}))

export const Content = styled(Stack)(({ theme }) => ({
  '&:has(div)': {
    marginTop: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    paddingTop: theme.spacing(1),
  },
}))

export const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  paddingTop: theme.spacing(1),
}))

export const Avatar = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '0 0 auto',
  paddingRight: theme.spacing(1),
}))

export const HeaderContent = styled('div')(() => ({
  flex: '1 1 auto',
}))

export const RightCorner = styled('div')(() => ({
  flex: '0 0 auto',
  alignSelf: 'flex-start',
}))

export const Title = styled(Typography)<{ size: 'large' | 'medium' | 'small' }>(({ theme, size }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.palette.grey[800],
  ...(size === 'large' && { ...theme.typography['Lato H5 Bold'] }),
  ...(size === 'medium' && { ...theme.typography['Lato Emphasized 1'] }),
  ...(size === 'small' && { ...theme.typography['Lato Emphasized 2'] }),
}))

export const SubTitle = styled(Typography)<{ size: 'large' | 'medium' | 'small' }>(({ theme, size }) => ({
  ...theme.typography[size === 'small' ? 'Lato Body 2' : 'Lato Body 1'],
  color: theme.palette.grey[600],
}))

export const Text = styled(Typography)<{ size: 'large' | 'medium' | 'small' }>(({ theme, size }) => ({
  ...theme.typography[size === 'small' ? 'Lato Body 2' : 'Lato Body 1'],
  paddingTop: theme.spacing(1),
  color: theme.palette.grey[800],
}))

export const Description = styled(Text)({
  display: 'flex',
  flexWrap: 'wrap',
})

Content.defaultProps = {
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}
