import React from 'react'

import { Box } from '@mui/material'

import * as S from './SectionItem.styles'

export type SectionItemProps = {
  orientation?: 'horizontal' | 'vertical'
  icon?: React.ReactNode
  value?: React.ReactNode | string
  label?: React.ReactNode | string
  className?: string
}

const SectionItem = ({ orientation = 'horizontal', icon, value, label, className }: SectionItemProps) => {
  const Wrapper = orientation === 'horizontal' ? React.Fragment : Box

  return (
    <S.Root className={className}>
      {icon && <S.Icon>{icon}</S.Icon>}

      <Wrapper>
        <S.Label orientation={orientation}>{label}</S.Label>
        <S.Value>{value}</S.Value>
      </Wrapper>
    </S.Root>
  )
}

export default SectionItem
